import env from '../switchers/env.js'


export default {
	cl: {
		channel: 'clickavia',
		events: env({
			default: 'https://www.clickavia.ru/api/events',
			stage: 'http://localhost:8000/api/events',
			dev: 'http://localhost:8000/api/events',
		}),
		offer: env({
			default: '/api/offer',
			stage: 'https://co.clickavia.ru/api/offer',
			dev: 'https://stage.clickavia.su/api/offer',
		}),
		order: env({
			default: '/api/order',
			stage: 'https://co.clickavia.ru/api/order',
			dev: 'https://stage.clickavia.su/api/order',
		}),
		package: env({
			default: '/api/package',
			stage: 'https://co.clickavia.ru/api/package',
			dev: 'https://stage.clickavia.su/api/package',
		}),
		book: env({
			default: '/api/order/:orderId/book',
			stage: 'https://co.clickavia.ru/api/order/:orderId/book',
			dev: 'https://stage.clickavia.su/api/order/:orderId/book',
		}),
		generateQr: env({
			default: '/api/order/:orderId/generate_qr',
			stage: 'https://co.clickavia.ru/api/order/:orderId/generate_qr',
			dev: 'https://stage.clickavia.su/api/order/:orderId/generate_qr',
		}),
		savePassengers: env({
			default: '/api/order/:orderId/save_passengers',
			stage: 'https://co.clickavia.ru/api/order/:orderId/save_passengers',
			dev: 'https://stage.clickavia.su/api/order/:orderId/save_passengers',
		}),
		createSbpPayment: env({
			default: '/api/order/:orderId/payment/new',
			stage: 'https://co.clickavia.ru/api/order/:orderId/payment/new',
			dev: 'https://stage.clickavia.su/api/order/:orderId/payment/new',
		}),
		checkSbpPayment: env({
			default: '/api/order/:orderId/payment/:paymentId/status',
			stage: 'https://co.clickavia.ru/api/order/:orderId/payment/:paymentId/status',
			dev: 'https://stage.clickavia.su/api/order/:orderId/payment/:paymentId/status',
		}),
		createTinkoffPayment: env({
			default: '/api/order/:orderId/payment/new',
			stage: 'https://co.clickavia.ru/api/order/:orderId/payment/new',
			dev: 'https://stage.clickavia.su/api/order/:orderId/payment/new',
		}),
		checkTinkoffPayment: env({
			default: '/api/order/:orderId/payment/:paymentId/status',
			stage: 'https://co.clickavia.ru/api/order/:orderId/payment/:paymentId/status',
			dev: 'https://stage.clickavia.su/api/order/:orderId/payment/:paymentId/status',
		}),
		payReturnUrl: env({
			default: `${window.location.origin}/offer/:offerId?order_id=:orderId`,
			stage: 'http://localhost:5563/offer/:offerId?order_id=:orderId',
			dev: 'http://localhost:5563/offer/:offerId?order_id=:orderId',
		}),
		gender: env({
			default: 'https://clickavia.ru/api/names',
			dev: 'http://crm.loc:8080/api/names',
		}),
		login: env({
			default: 'https://www.clickavia.ru/api/login.json',
			stage: 'https://www.clickavia.ru/api/login.json',
			dev: 'http://crm.loc:8080/api/login.json',
		}),
		loginByToken: env({
			default: 'https://www.clickavia.ru/api/login_by_token.json',
			stage: 'https://www.clickavia.ru/api/login_by_token.json',
			dev: 'http://crm.loc:8080/api/login_by_token.json',
		}),
		logout: env({
			default: 'https://www.clickavia.ru/api/logout.json',
			stage: 'https://www.clickavia.ru/api/logout.json',
			dev: 'http://crm.loc:8080/api/logout.json',
		}),
		accountData: {
			url: env({
				default: 'https://www.clickavia.ru/api/orders',
				stage: 'https://www.clickavia.ru/api/orders',
				dev: 'http://crm.loc:8080/api/orders',
			}),
			acceptHeader: 'application/vnd.clickavia.v3+json',
		},
		currentUser: env({
			default: 'https://www.clickavia.ru/api/current_user',
			stage: 'https://www.clickavia.ru/api/current_user',
			dev: 'http://crm.loc:8080/api/current_user',
		}),
		passwordRecovery: env({
			default: 'https://www.clickavia.ru/api/users/password.json',
			stage: 'https://www.clickavia.ru/api/users/password.json',
			dev: 'http://crm.loc:8080/api/users/password.json',
		}),
		feedback: env({
			default: 'https://www.clickavia.ru/api/inquiries',
			stage: 'https://www.clickavia.ru/api/inquiries',
			dev: 'http://crm.loc:8080/api/inquiries',
		}),
		/* eslint-disable camelcase*/
		feedback_v2: env({
			default: 'https://www.clickavia.ru/api/orders',
			stage: 'https://www.clickavia.ru/lk-api/orders',
			dev: 'http://crm.loc:8080/api/orders',
		}),
		search: env({
			default: '/api/search/ws',
			stage: 'wss://co.clickavia.ru/api/search/ws',
			dev: 'wss://stage.clickavia.su/api/search/ws',
		}),
		hotel: env({
			default: '/api/hotel',
			stage: 'https://co.clickavia.ru/api/hotel',
			dev: 'https://stage.clickavia.su/api/hotel',
		}),
		createOffer: env({
			default: '/api/offer/new',
			stage: 'https://co.clickavia.ru/api/offer/new',
			dev: 'https://stage.clickavia.su/api/offer/new',
		}),
		topPrices: env({
			default: 'api/calendar/top',
			stage: 'https://co.clickavia.ru/api/calendar/top',
			dev: 'https://stage.clickavia.su/api/calendar/top',
		}),
		pricesByMonth: env({
			default: 'api/calendar/by_month',
			stage: 'https://co.clickavia.ru/api/calendar/by_month',
			dev: 'https://stage.clickavia.su/api/calendar/by_month',
		}),
		pricesByDay: env({
			default: 'api/calendar/by_day',
			stage: 'https://co.clickavia.ru/api/calendar/by_day',
			dev: 'https://stage.clickavia.su/api/calendar/by_day',
		}),
	},
	geo: {
		locations: env({
			default: 'https://geo.clickavia.ru/locations',
		}),
		lookupByIp: env({
			default: 'https://geo.clickavia.ru/lookup_by_ip',
		}),
		localities: env({
			default: 'https://geo.clickavia.ru/localities',
		}),
		airports: env({
			default: 'https://geo.clickavia.ru/airports',
		}),
	},
	static: {
		brandsLogosPath: env({
			default: '/static/card-logos/',
			stage: 'https://co.clickavia.ru/static/card-logos/',
			dev: 'https://co.clickavia.ru/static/card-logos/',
		}),
		banksLogosPath: env({
			default: '/static/bank-logos/',
			stage: 'https://co.clickavia.ru/static/bank-logos/',
			dev: 'https://co.clickavia.ru/static/bank-logos/',
		}),
		airlineImg: env({
			default: 'https://www.clickavia.ru/api/airlines/logo/',
		}),
	},

	sociomantic: {
		product: env({
			default: '/api/somic/product',
			stage: 'https://newapi-staging.travelab.com/somic/product',
			dev: 'https://stage.clickavia.su/somic/product',
		}),
		productClick: env({
			default: '/api/somic/product/:product_id/click',
			stage: 'https://newapi-staging.travelab.com/somic/product/:product_id/click',
			dev: 'https://stage.clickavia.su/somic/product/:product_id/click',
		}),
	},
	tracker: {
		s: env({
			/* eslint-disable max-len */
			default: 'https://tracker.travelab.com/s/:id/:src/:dst/:depart/:return/:adults/:children/:infants/:channel/r',
		}),
		sync: env({
			default: 'https://tracker.travelab.com/sync',
		}),
	},

	// Example of rich use for utils/sourcer
	/*main: {
		protocol: protocol(),
		host: env({
			default: 'api-staging.travelab.com:8890',
			mock: 'localhost:8080',
		}),
		endpoints: {
			search: 'SSE /search?:coreQuery&:extraQuery',
		},
	},
	geo: {
		protocol: protocol(),
		host: 'geo.clickavia.ru',
		endpoints: {
			locations: 'GET /locations',
		},
	},*/
}
