import request from 'superagent'
import confy from '@utils/confy'
import getResponseHandler from './responseCheckSbpHandler.js'
import { OPERATION_STATUSES } from './statuses.js'


const ERROR_STATUSES = [
	OPERATION_STATUSES.ERROR,
]
const OK_STATUSES = [
	OPERATION_STATUSES.SUCCESS,
]

export default function ({ orderId, paymentId }) {
	const url = `${confy.get('API.cl.checkSbpPayment')}`.replace(':orderId', orderId).replace(':paymentId', paymentId)

	const req = request
		.get(url)
		.send({})

	const executor = (resolve, reject) => {
		const handler = getResponseHandler({
			resolve,
			reject,
			omitData: true,
			okStatuses: OK_STATUSES,
			errStatuses: ERROR_STATUSES,
			requestType: 'GET /checkSpbPayment',
		})

		req.end(handler)
	}

	return {
		promise: new Promise(executor),
		cancelFn: () => req.abort(),
	}
}
